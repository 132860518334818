<template>
    <div class="pwdset">
        <div class="pwdsetForm">
            <el-form ref="pwdsetForm" :label-position="labelPosition" :model="pwdsetForm" :rules="rules" label-width="100px">
                <el-form-item label="新密码" prop="newPassword">
                    <el-input v-model="pwdsetForm.newPassword" placeholder="请输入新密码" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="confirmPwd">
                    <el-input v-model="pwdsetForm.confirmPwd" placeholder="请再次输入新密码" show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('pwdsetForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import {updatePassword} from "../../../api/systemService";
import storage from "../../../utils/storage";

export default {
    components: {},
    data() {

        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                var pPattern = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;
                if (pPattern.test(value)) {
                    callback();
                } else {
                    callback(new Error('最少6位(包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符)'))
                }

            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.pwdsetForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };

        return {
            labelPosition: 'left',
            pwdsetForm: {
                newPassword: '',
                confirmPwd: ''
            },

            rules: {
                newPassword: [
                    {validator: validatePass, required: true, trigger: 'blur'}
                ],
                confirmPwd: [
                    {validator: validatePass2, required: true, trigger: 'blur'}
                ]

            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const user = storage.get('user');
                    const username = user.user_name;
                    let pwd = '';
                    if (this.pwdsetForm.newPassword === this.pwdsetForm.confirmPwd) {
                        pwd = this.pwdsetForm.newPassword
                    }
                    const data = {username: username, passWord: pwd};
                    updatePassword(data).then(res => {
                        if (res.code === '0' && res.data === 'true') {
                            this.$message({
                                message: '修改成功，请重新登录！',
                                type: 'success'
                            });
                            storage.remove("user");
                            this.$router.push({
                                path: '/login'
                            })
                        }
                    });
                }
            });
        }
    }
}
</script>
<style lang="scss">
.pwdset {
  // border:1px solid blueviolet;
  height: 582px;

  .pwdsetForm {
    padding: 40px 158px;

    .verifCode {
      .el-button--primary {
        width: 138px;
        padding: 10px 0px;
        margin-left: 15px;
      }

      .el-input {
        width: 309px;
      }
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #FFFFFF;
    }

    .el-button--primary {
      width: 100%;
    }
  }
}
</style>
